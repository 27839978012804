import React, { useContext, useEffect, useState } from 'react';
import themes from '~/utils/themes';
import SEO from '~/components/Seo';
import Footer from '~/components/Footer';
import { COLLECTIONS, COLLECTION_NAMES } from '~/utils/constants';
import { ShopifyDataContext } from '~/context/ShopifyDataContext';
import { fetchLatestShopifyArticle } from '~/utils/api';
import LatestNewsSection from '~/components/LatestNewsSection';
import CollectionSection from '~/components/CollectionSection';
import { graphql } from 'gatsby';
// import { motion, useAnimation } from 'framer-motion';

interface DataProps {
    data: {
        storeFront: {
            shop: {
                displayLatestNewsArticle: {
                    key: string;
                    value: string; // String value "true" or "false"
                }
            }
        }
    }
}

// const variants = {
//     hidden: {
//         opacity: 0,
//         y: 8,
//     },
//     visible: {
//         opacity: 1,
//         y: 0,
//         transition: {
//             duration: 0.5,
//         },
//     },
// };

// CollectionSection.defaultProps = {
//     loading: undefined,
// };

const IndexPage = ({ data }: DataProps) => {
    const shopifyData = useContext(ShopifyDataContext);
    const { latestNewsArticle, setLatestNewsArticle } = shopifyData;
    const collectionNames: string[] = [];
    const { displayLatestNewsArticle } = data.storeFront?.shop || {};
    const [mounted, setMounted] = useState(true);
    const [loading, setLoading] = useState(false);
    const displayArticle = displayLatestNewsArticle?.value === 'true';

    useEffect(() => {
        const fetchLatestArticle = async () => {
            setLoading(true);
            try {
                const article = await fetchLatestShopifyArticle();

                if (article && mounted) {
                    setLatestNewsArticle(article);
                    setLoading(false);
                }
            } catch (e) {
                console.log('Error fetching latest article', e);

                if (mounted) {
                    setLoading(false);
                }
            }
        };

        if (displayArticle && mounted && !loading && !latestNewsArticle) {
            fetchLatestArticle();
        }

        return () => setMounted(false);
    }, [displayArticle, mounted, loading, latestNewsArticle, setLatestNewsArticle]);

    COLLECTION_NAMES.forEach((name) => {
        if (shopifyData.collections?.[name]) {
            collectionNames.push(name);
        }
    });

    return (
        <>
            <SEO title="Home" />

            {latestNewsArticle && (
                <LatestNewsSection
                    article={latestNewsArticle}
                />
            )}

            {collectionNames.map((collectionName) => (
                <CollectionSection
                    key={collectionName}
                    // loading={loading}
                    collection={shopifyData.collections[collectionName]}
                    collectionName={collectionName as keyof typeof COLLECTIONS}
                />
            ))}

            <Footer
                themeColor={themes.white}
            />
        </>
    );
};

export const query = graphql`
    query {
        storeFront {
            shop {
                displayLatestNewsArticle: metafield(namespace: "global", key: "feature_latest_article") {
                    key
                    value
                }
            }
        }
    }
`;

export default IndexPage;
