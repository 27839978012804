import React from 'react';
import styled from '@emotion/styled';
import { Link as GatsbyLink } from 'gatsby';
import { CollectionInterface } from '~/utils/types';
import Link from '~/components/Link';
import ProductGrid from '~/components/ProductGrid';
import useThemeSettingSection from '~/hooks/use-theme-setting-section';
import { COLLECTIONS, PRODUCT_PREVIEW_LIMIT } from '~/utils/constants';
import { getCollectionLink, getPreviewCollection } from '~/utils';
import { breakpoints, Section } from '~/utils/styles';
import themes from '~/utils/themes';

const CollectionHeader = styled.header(({ shouldCenter }: { shouldCenter: boolean }) => `
    display: flex;
    justify-content: ${shouldCenter ? 'center' : 'space-between'};
    align-items: baseline;
    margin-bottom: 3rem;

    @media ${breakpoints.lg} {
        display: block;
        text-align: center;
    }
`);

const CollectionHeadline = styled.h3`
    font-family: 'BrancherBeta-Regular', sans-serif;
    font-size: 1.25rem;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.02em;
    margin: 0;

    @media ${breakpoints.lg} {
        font-size: 2rem;
    }
`;

const CollectionSubHeadline = styled.h4`
    font-size: 1rem;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    margin-left: 25px;
    margin-top: 0;
    margin-bottom: 0;
    text-transform: uppercase;
    letter-spacing: 0.02em;

    @media ${breakpoints.lg} {
        font-size: 1rem;
        margin-left: 0;
    }
`;

const CollectionFooter = styled.div`
    text-align: center;
    text-transform: uppercase;
    margin-top: 80px;

    @media ${breakpoints.md} {
        margin-top: 120px;
    }
`;

const StyledGatsbyLink = styled(GatsbyLink)`
    color: inherit;
    text-decoration: none;
`;

interface SectionProps {
    collectionName: keyof typeof COLLECTIONS;
    collection: CollectionInterface
}

const CollectionSection = ({ collectionName, collection }: SectionProps) => {
    const { products } = collection || {};
    const previewLen = PRODUCT_PREVIEW_LIMIT;
    const hasMoreThanPreview = products?.length > previewLen || false;
    const collectionData = getPreviewCollection(collection, previewLen);
    const { title } = collectionData || {};
    const collectionTitleId = title.toLowerCase().replace(/\s/g, '');
    const theme = COLLECTIONS[collectionName].theme ?? themes.default;
    const { ref } = useThemeSettingSection(theme);

    return (
        <Section
            ref={ref}
            id={collectionTitleId}
        >
            <CollectionHeader shouldCenter={!hasMoreThanPreview}>
                <CollectionHeadline>
                    <StyledGatsbyLink to={getCollectionLink(collection)}>
                        {title}
                    </StyledGatsbyLink>
                </CollectionHeadline>

                {hasMoreThanPreview && (
                    <CollectionSubHeadline>
                        Top picks
                    </CollectionSubHeadline>
                )}
            </CollectionHeader>

            <ProductGrid
                collectionsData={collectionData}
            />

            <CollectionFooter>
                {products?.length > 0 && (
                    <Link to={getCollectionLink(collection)}>
                        See all
                        {' '}
                        {title}
                    </Link>
                )}
            </CollectionFooter>
        </Section>
    );
};

export default CollectionSection;
