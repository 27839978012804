import React from 'react';
import { NewsArticleInterface } from '~/utils/types';
import NewsArticle from '~/components/NewsArticle';
import themes from '~/utils/themes';
import useThemeSettingSection from '~/hooks/use-theme-setting-section';
import styled from '@emotion/styled';
import { breakpoints, Section } from '~/utils/styles';
import { Link } from 'gatsby';

const NewsSection = styled(Section)`
    padding-left: 0;
    padding-right: 0;
    padding-top: 100px;
    padding-bottom: 100px;
    height: calc(100vh - 49px - 120px);

    @media ${breakpoints.lg} {
        display: flex;
        padding-top: 40px;
        padding-bottom: 100px;
        height: 100vh;
        height: calc(100vh - 67px - 40px - 10px);
        min-height: 480px;
    }
`;

const NewsArchiveArticleWrapper = styled.div`
    width: 100%;
    // max-width: 615px;
    margin: auto;
    height: 100%;
`;

const StyledLink = styled(Link)`
appearance: none;
color: inherit;
text-decoration: inherit;
`;

const LatestNewsSection = ({ article }: { article: NewsArticleInterface; }) => {
    const { colorScheme } = article;
    const theme = colorScheme
        ? themes[colorScheme.value] ?? themes.pink
        : themes.pink;

    const { ref } = useThemeSettingSection(theme);

    // const controls = useAnimation();

    // useEffect(() => {
    //     if (inView) {
    //         controls.start('visible');
    //     }
    // }, [controls, inView]);

    return (
        <NewsSection
            ref={ref}
            id="news"
        >
            <NewsArchiveArticleWrapper>
                {/* <motion.div
                    initial="hidden"
                    animate={controls}
                    variants={variants}
                > */}
                <StyledLink
                    to="/news"
                >
                    <NewsArticle
                        key={article.id}
                        article={article}
                    />
                </StyledLink>
                {/* </motion.div> */}
            </NewsArchiveArticleWrapper>
        </NewsSection>
    );
};

export default LatestNewsSection;
